<template>
  <div class="incidents-container">
    <!-- Filter Section -->
    <div class="accordion" id="incidentsFilterAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingIncidentsFilter">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#incidentsFilters"
            aria-expanded="true" aria-controls="incidentsFilters">
            Filters (Click to Open)
          </button>
        </h2>
        <div id="incidentsFilters" class="accordion-collapse collapse show" aria-labelledby="headingIncidentsFilter">
          <div class="accordion-body">
            <div class="row">
              <!-- Start Date Filter -->
              <div class="col-md-6 col-sm-12">
                <label for="startDateFilter">Start Date:</label>
                <input id="startDateFilter" type="date" v-model="startDateFilter" class="form-control"
                  placeholder="Filter by Start Date" />
              </div>
              <!-- End Date Filter -->
              <div class="col-md-6 col-sm-12">
                <label for="endDateFilter">End Date:</label>
                <input id="endDateFilter" type="date" v-model="endDateFilter" class="form-control"
                  placeholder="Filter by End Date" />
              </div>
              <!-- Incident ID Filter -->
              <div class="col-md-6 col-sm-12">
                <label for="incidentIdFilter">Incident ID:</label>
                <input id="incidentIdFilter" type="text" v-model="incidentIdFilter" class="form-control"
                  placeholder="Filter by Incident ID" />
              </div>
              <!-- Status Filter -->
              <div class="col-md-6 col-sm-12">
                <label for="statusFilter">Status:</label>
                <select id="statusFilter" v-model="statusFilter" class="form-control">
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                  <option value="AwaitingEvidence">Awaiting Evidence</option>
                </select>
              </div>
              <!-- Site Filter Dropdown -->
              <div class="col-md-4 col-sm-12">
                <label for="siteFilter">Site Filter</label>
                <select id="siteFilter" class="form-control" v-model="selectedSiteId" @change="onSiteChange">
                  <option disabled value="">Select a site</option>
                  <option v-for="site in sites" :value="site.id" :key="site.id">{{ site.name }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <button class="btn btn-primary w-100" @click="fetchIncidents(1)">Search</button>
              </div>
              <div class="col-6">
                <button class="btn btn-secondary w-100" @click="clearFilters">Clear Filters</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add the new incident button here -->
    <!-- <button class="btn btn-success mb-4 mt-3" @click="createIncident">Create New Incident</button> -->

    <!-- Pagination Component -->
    <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" />

    <!-- Incidents Display Section -->
    <ul class="list-group">
      <li v-for="incident in incidents" :key="incident.id" class="list-group-item">
        <div class="row">
          <div class="col-12 col-lg-8">
            <h5 class="mb-2 fw-bold">Incident Description</h5>
            <div class="description-container">
              <p v-if="isDescriptionShort(incident.description)">
                {{ incident.description }}
              </p>
              <p v-else>
                {{ incident.expanded ? incident.description : shortenDescription(incident.description) }}...
                <button class="btn btn-link p-0" @click.prevent="toggleDescription(incident)">
                  {{ incident.expanded ? 'Read less' : 'Read more' }}
                </button>
              </p>
            </div>
            <p>
              <large class="text-muted">Created on: {{ formatDate(incident.created_at) }}</large>
            </p>
            <p>
              <large class="text-muted">Incident Date: {{ formatDate(incident.happened_at) }}</large>
            </p>
            <p>Police Reference: {{ incident.police_ref || 'N/A' }}</p>
            <!-- Display Incident ID -->
            <p>Incident ID: {{ incident.id }}</p>
            <div
              :class="[statusBgClass(incident.status), 'p-2', 'rounded', 'd-flex', 'justify-content-center', 'align-items-center']">
              <p class="mb-0">Status: {{ formatStatus(incident.status) }}</p>
            </div>
            <!-- Toggle Button for Upload Section -->
            <button class="btn btn-outline-primary btn-sm mt-2 dropdown-toggle" type="button"
              @click="toggleUploadSection(incident.id)">
              {{ uploadSectionVisible[incident.id] ? 'Hide Upload Options' : 'Show Upload Options' }}
            </button>
            <!-- Conditional Evidence,description, police ref Upload Field -->
            <div v-if="uploadSectionVisible[incident.id] && ['Rejected', 'AwaitingEvidence'].includes(incident.status)"
              class="mt-3">
              <!-- New Description -->
              <div class="mb-3">
                <label class="form-label">New Description:</label>
                <textarea class="form-control" v-model="incident.newDescription"
                  placeholder="Enter new description"></textarea>
              </div>

              <!-- New Police Reference -->
              <div class="mb-3">
                <label class="form-label">New Police Reference:</label>
                <input type="text" class="form-control" v-model="incident.newPoliceRef"
                  placeholder="Enter new police reference">
              </div>
              <!-- File upload -->
              <input type="file" class="form-control" @change="handleFileUpload($event, incident.id)" multiple
                accept="image/png, image/jpeg, application/pdf" />

              <!-- Display Selected Files Before Upload -->
              <div v-if="selectedFiles[incident.id] && selectedFiles[incident.id].length">
                <ul>
                  <li v-for="(file, index) in selectedFiles[incident.id]" :key="`file-${incident.id}-${index}`">
                    {{ file.name }}
                    <button type="button" class="btn btn-outline-danger btn-sm m-1"
                      @click="removeFile(incident.id, index)">Remove</button>
                  </li>
                </ul>
              </div>

              <button class="btn btn-info btn-sm mt-2" @click="uploadEvidence(incident.id)">Upload New Evidence</button>
            </div>

            <!-- Rejected Reason Section -->
            <div
              v-if="(incident.status === 'Rejected' || incident.status === 'AwaitingEvidence') && incident.rejection_reason">
              <p class="text-danger mt-2">
                <strong>Rejection Reason:</strong> {{ incident.rejection_reason }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <!-- Display image if available -->
            <img v-if="incidentImages[incident.id]" @contextmenu.prevent :src="incidentImages[incident.id][0]"
              alt="Incident Image" class="incident-image" />
          </div>
        </div>
      </li>
    </ul>

    <!-- Pagination Component -->
    <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" class="pagination-status" />
  </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../../components/Pagination.vue';
import { v4 as uuidv4 } from 'uuid';
import authHeader from "../../services/auth-header";
import { globalState } from './globalState.js';
import ClientService from '../../services/client.service.js';

export default {
  name: "Incidents",
  components: {
    Pagination
  },
  data() {
    return {
      incidents: [],
      incidentImages: {},
      pagination: {
        page: 1,
        showing: 25,
        total: 0,
        lastPage: 0,
        maxPage: 0,
        pageSize: 25
      },
      startDateFilter: "",
      endDateFilter: "",
      incidentIdFilter: "",
      statusFilter: "",
      site_id: null,
      claim: {
        site: null,
        postcode: null,
        comments: null,
        fuels: [
          {
            id: Math.floor((Math.random() * 10) + 1),
            type: "",
            value: null,
            litres: null,
            pump: null
          }
        ],
        evidence: [
          {
            id: uuidv4(),
            file: null,
            type: "vehicle_image",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          },
          {
            id: uuidv4(),
            file: null,
            type: "till_receipt",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          }
        ],
        accept_agreement: false,
        date: null,
        time: null,
      },
      selectedFiles: {},
      uploadSectionVisible: {},
      sites: [],
      selectedSiteId: null,
    };
  },

  computed: {
    siteId() {
      console.log(`COMING FROM COMPUTED SITE ID`, globalState.selectedSiteId);
      return globalState.selectedSiteId;
    }
  },

  watch: {
    siteId(newSiteId, oldSiteId) {
      if (newSiteId !== oldSiteId) {
        this.fetchIncidents(); // Update your incidents based on the new siteId
      }
    }
  },

  async mounted() {
    await this.loadSites();
    // this.site_id = this.siteId;
    this.site_id = localStorage.getItem('selectedSiteId');
    this.fetchIncidents();
  },

  methods: {
    async fetchIncidents(page = 1) {
      // Update current page
      this.pagination.page = page;

      const params = {
        start: this.startDateFilter,
        end: this.endDateFilter,
        site_id: this.site_id,
        errorMessage: "",
        page: this.pagination.page,
        count: this.pagination.pageSize,
        incident_id: this.incidentIdFilter.trim(),
      };

      // Prepare custom headers
      // const headers = {
      //   'x-vars-claims': '{"id":4,"permissions":["incident.read", "incident.create", "faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
      // };

      try {
        if (this.incidentIdFilter.trim()) {
          // Fetch a specific incident
          const response = await axios.get("https://api.varsanpr.com/customer/faces/incidents", { params, headers: authHeader() });
          if (response.data && response.data.incident) {
            this.incidents = [response.data.incident]; // Set incidents array to contain only the searched incident
            this.pagination.total = 1; // Since only one incident is being displayed
            this.pagination.maxPage = 1;
            this.fetchIncidentImage(response.data.incident.id); // Fetch the image for the specific incident
            if (this.uploadSectionVisible[response.data.incident.id] === undefined) {
              this.uploadSectionVisible[response.data.incident.id] = false;
            }
          } else {
            console.error("No incident found for the given ID");
          }
        } else {
          const response = await axios.get("https://api.varsanpr.com/customer/faces/incidents", { params, headers: authHeader() });
          if (response.data && response.data.incidents) {
            this.incidents = response.data.incidents;
            // Apply status filter if set
            if (this.statusFilter) {
              this.incidents = this.incidents.filter(
                incident => incident.status === this.statusFilter
              );
            }
            this.pagination.total = response.data.pagination.total;
            this.pagination.maxPage = Math.ceil(this.pagination.total / this.pagination.pageSize);
            console.log(`COMING FROM FETCH INCIDENTS this.incidents`, this.incidents);
            // After fetching incidents, fetch images for each incident
            this.incidents.forEach(incident => {
              this.fetchIncidentImage(incident.id);
              if (this.uploadSectionVisible[incident.id] === undefined) {
                this.uploadSectionVisible[incident.id] = false;
              }
            });
          } else {
            console.error("No incidents found in response");
          }
        }
      } catch (error) {
        console.error("Error fetching incidents:", error);
        this.errorMessage = "Failed to fetch incidents. Please try again."; // Display this message in UI
      }
    },

    formatDate(dateString) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'
      };
      return new Date(dateString).toLocaleDateString("en-GB", options);
    },

    isDescriptionShort(description) {
      return description.length < 100;
    },

    shortenDescription(description) {
      return description.substring(0, 100);
    },

    toggleDescription(incident) {
      incident.expanded = !incident.expanded;
    },

    clearFilters() {
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.incidentIdFilter = "";
      this.statusFilter = "";
      this.fetchIncidents();
    },

    createIncident() {
      this.$router.push('/face-incident-form');
    },

    onPageChange(page) {
      this.fetchIncidents(page);
    },

    formatStatus(status) {
      // Function to format status string
      switch (status) {
        case 'Pending':
        case 'Approved':
        case 'Rejected':
          return status;
        case 'AwaitingEvidence':
          return 'Awaiting Evidence';
        default:
          return status;
      }
    },

    statusBgClass(status) {
      // Updated to use the formatStatus method
      const formattedStatus = this.formatStatus(status);
      switch (formattedStatus) {
        case 'Pending':
          return 'bg-warning';
        case 'Approved':
          return 'bg-success';
        case 'Rejected':
          return 'bg-danger';
        case 'Awaiting Evidence': // Updated to match formatted status
          return 'bg-info';
        default:
          return '';
      }
    },


    async fetchIncidentImage(incidentId) {

      const params = {
        site_id: this.site_id,
        incident_id: incidentId,
      };

      // Prepare custom headers
      // const headers = {
      //   'x-vars-claims': '{"id":4,"permissions":["incident.read", "incident.create", "faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
      // };

      try {
        const response = await axios.get("https://api.varsanpr.com/customer/faces/incidents", { params, headers: authHeader() });
        if (response.data.success && response.data.people.length > 0) {
          // Assuming the first person's first face is what you want to display
          // this.incidentImages[incidentId] = response.data.people.map(person => person.faces[0].sas);
          // console.log("laknfkjsadbflbn", this.incidentImages);
          const closestFace = this.findClosestFace(response.data.people[0].faces, response.data.incident.created_at);
          if (closestFace) {
            // this.incidentImages[incidentId] = closestFace.sas;
            this.incidentImages[incidentId] = closestFace.map(face => face.sas);
          }
        }
      } catch (error) {
        console.error(`Error fetching image for incident ${incidentId}:`, error);
      }
    },

    findClosestFace(faces, incidentCreatedAt) {
      const incidentTimestamp = new Date(incidentCreatedAt).getTime() / 1000;

      const closestFace = faces.reduce((closest, face) => {
        const faceTimeStamp = face.created_at;
        const closestTimeStamp = closest.created_at;
        const faceDiff = Math.abs(faceTimeStamp - incidentTimestamp);
        const closestDiff = Math.abs(closestTimeStamp - incidentTimestamp);

        return faceDiff < closestDiff ? face : closest;
      }, faces[0])

      const closestFaces = [closestFace];

      faces.forEach(face => {
        if (face.created_at === closestFace.created_at && face !== closestFace) {
          closestFaces.push(face);
        };
      });

      return closestFaces;
    },

    handleFileUpload(event, incidentId) {
      // Update selectedFiles with new files
      if (Array.isArray(this.selectedFiles[incidentId])) {
        this.selectedFiles[incidentId] = [...this.selectedFiles[incidentId], ...event.target.files];
      } else {
        this.selectedFiles[incidentId] = Array.from(event.target.files);
      }
    },

    removeFile(incidentId, fileIndex) {
      // Remove a specific file
      this.selectedFiles[incidentId].splice(fileIndex, 1);
    },

    async uploadEvidence(incidentId) {
      const incident = this.incidents.find(inc => inc.id === incidentId);
      const files = this.selectedFiles[incidentId] || [];

      // Check if there's anything to update (either files, new description, or new police ref)
      if (files.length === 0 && !incident.newDescription && !incident.newPoliceRef) {
        this.$error("Please select files to upload or enter a new description or police reference.");
        return;
      }

      const formData = new FormData();

      // Append files if there are any
      files.forEach(file => {
        formData.append('files', file);
      });

      // Append new description and police reference if available
      if (incident.newDescription) {
        formData.append('description', incident.newDescription);
      }
      if (incident.newPoliceRef) {
        formData.append('police_ref', incident.newPoliceRef);
      }


      formData.append('id', incidentId);
      formData.append('site_id', this.site_id);

      try {
        const response = await axios.patch("https://api.varsanpr.com/customer/faces/incidents", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            ...authHeader(),
          },
        })

        if (response.data.success) {
          // Handle success
          this.$success("Evidence uploaded successfully.");
          this.selectedFiles[incidentId] = [];// Clear files after upload

          // Find the incident in your incidents array and reset its new description and new police ref
          const incidentIndex = this.incidents.findIndex(inc => inc.id === incidentId);
          if (incidentIndex !== -1) {
            this.incidents[incidentIndex].newDescription = '';
            this.incidents[incidentIndex].newPoliceRef = '';
          }
        } else {
          this.$error("Failed to upload evidence. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading evidence:", error);
        this.$error("An error occurred while uploading evidence.");
      }
    },

    toggleUploadSection(incidentId) {
      // Check if the property exists; if not, initialize it directly
      if (this.uploadSectionVisible[incidentId] !== undefined) {
        this.uploadSectionVisible[incidentId] = !this.uploadSectionVisible[incidentId];
      } else {
        // Direct assignment for Vue 3 reactivity
        this.uploadSectionVisible[incidentId] = true;
      }
    },

    onSiteChange() {
      this.site_id = this.selectedSiteId;
      this.fetchIncidents(1);
      globalState.selectedSiteId = this.selectedSiteId;
      localStorage.setItem('selectedSiteId', this.selectedSiteId);
    },

    async loadSites() {
      try {
        const response = await ClientService.getClientSites(this.$store.state.auth.user.selectedClient);
        this.sites = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        console.log(this.sites);
        // Iterate through sites to find a working one

        for (const site of this.sites) {
          const isSiteValid = await this.testSite(site.id);
          if (isSiteValid) {
            this.selectedSiteId = site.id;
            globalState.selectedSiteId = this.selectedSiteId;
            localStorage.setItem('selectedSiteId', site.id);
            await this.fetchIncidents(1, site.id);
            break;
          }
        }
      } catch (error) {
        console.error('Error loading sites:', error);
      }
    },

    async testSite(siteId) {
      try {
        const response = await axios.get("https://api.varsanpr.com/customer/faces/faces", {
          params: {
            site_id: siteId,
            page: 1,
            count: this.pagination.pageSize
          },
          headers: authHeader()
        });

        // Check if the response is successful and faces data is available
        if (response.status === 200 && response.data.faces && response.data.faces.length > 0) {
          return true; // Site is working and has faces data
        }
        return false; // Site may not have faces data or not working
      } catch (error) {
        console.error(`Error testing site ${siteId}:`, error);
        return false; // Site is not working
      }
    },
  },
};
</script>

<style scoped>
.incidents-container {
  padding: 20px;
}

.incidents-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.incident-card {
  margin: 10px;
  width: 300px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 15px;
  text-align: left;
}

.card-title {
  font-size: 1.25em;
  margin-bottom: 0.5em;
}

.card-subtitle {
  margin-bottom: 1em;
}

.card-text {
  font-size: 1em;
}

.description-container {
  word-wrap: break-word;
  /* Ensure long words do not overflow */
}

.list-group-item {
  margin-top: 10px;
}

.bg-warning {
  background-color: #ffc107;
  color: black;
}

.bg-success {
  background-color: #28a745;
  color: white;
}

.bg-danger {
  background-color: #dc3545;
  color: white;
}

.bg-info {
  background-color: #17a2b8;
  color: white;
}

/* Styling for status container */
.status-container {
  min-height: 50px;
  /* Ensures uniform height */
  width: 100%;
  /* Ensure full width within its column */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .status-container {
    margin-top: 15px;
    /* Extra spacing on smaller screens */
  }
}

.incident-image {
  width: 50%;
  height: auto;
  margin: auto;
  border-radius: 4px;
  display: block;
  /* margin-top: 15px; */
}

.related-person-image {
  max-width: 100px;
  height: auto;
  border-radius: 50%;
  margin: 5px;
}
</style>
